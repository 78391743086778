import { template as template_40e296170b904088bbd49b6c80ecae76 } from "@ember/template-compiler";
const FKText = template_40e296170b904088bbd49b6c80ecae76(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
